import React from "react";

import { Button } from '@mui/material';

const TwitchLoginButton = () => {
    const state = encodeURIComponent('asdf');

    const handleLogin = () => {
        const clientId = 'mym23z65ew7hk7tyhfmq41zwxse4ek';
        const redirectUri = encodeURIComponent('https://livedb.tv/login/twitch/redirect');
        const scope = encodeURIComponent('user:read:email');
        const url = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;

        window.location.href = url;
    };

    return (
        <Button onClick={handleLogin} variant="contained" color="secondary" style={{ margin: '30px auto', textTransform: 'none' }} >Login with Twitch</Button>
    );
};

export default TwitchLoginButton;
