import React, { useState, useEffect, useMemo, useCallback } from 'react';

interface UserCategoryPerformanceProps {
    username: string;
}

interface GameData {
    gameId: string;
    gameName: string;
    avgViewers: number;
    maxViewers: number;
    minViewers: number;
    stdDeviation: number;
    timeStreamed: number;
}

export default function UserCategoryPerformance({ username }: UserCategoryPerformanceProps) {
    const [selectedPeriod, setSelectedPeriod] = useState('7 days');
    const [gameData, setGameData] = useState<GameData[] | null>(null);
    const [providerId, setProviderId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const periods = useMemo(() => [
        {label: '7 days', value: '168h'},
        {label: '1 month', value: '720h'},
        {label: '3 months', value: '2160h'}
    ], []);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userResponse = await fetch(`https://api.livedb.tv/v1/data/user/twitch/username/${username}`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'X-API-Token': 'INCYtnv3hbRC0kMx17uYOYppRXPxupBL',
                    },
                });
                if (!userResponse.ok) {
                    throw new Error(`HTTP error! status: ${userResponse.status}`);
                }
                const userData = await userResponse.json();
                setProviderId(userData.provider_id);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [username]);

    const fetchData = useCallback(async () => {
        if (!providerId) return;
        setIsLoading(true);
        setError(null);
        const selectedPeriodValue = periods.find(p => p.label === selectedPeriod)?.value;
        try {
            const response = await fetch(`https://api.livedb.tv/v1/blackbox/games/twitch/id/${providerId}/viewers?range=${selectedPeriodValue}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setGameData(data.data);
        } catch (error) {
            console.error('Error fetching game data:', error);
            setError('Failed to fetch game data. Please try again later.');
            setGameData(null);
        } finally {
            setIsLoading(false);
        }
    }, [providerId, selectedPeriod, periods]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const sortedGameData = useMemo(() => {
        return gameData ? [...gameData].sort((a, b) => b.avgViewers - a.avgViewers) : null;
    }, [gameData]);

    const formatTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}h ${remainingMinutes}m`;
    };

    const totalAverage = useMemo(() => {
        if (gameData) {
            const totalAvg = gameData.reduce((acc, curr) => acc + curr.avgViewers, 0);
            return Math.round(totalAvg / gameData.length);
        }
        return 0;
    }, [gameData]);

    if (!isLoading && !sortedGameData) {
        fetchData();
        return <div>Loading...</div>;
    }

    return (
        <div className="user-category-performance">
            <h3 className="performance-title">
                Category performance for {username} - past {selectedPeriod}
            </h3>
            <div className="period-selector">
                {periods.map((period) => (
                    <button
                        key={period.label}
                        onClick={() => setSelectedPeriod(period.label)}
                        className={`period-button ${period.label === selectedPeriod ? 'selected' : ''}`}
                    >
                        {period.label}
                    </button>
                ))}
            </div>

            {isLoading ? (
                <div>Loading...</div>
            ) : error ? (
                <div>Error: {error}</div>
            ) : sortedGameData && sortedGameData.length > 0 ? (
                <div>
                    <p>Total average viewers: {totalAverage}</p>
                    <table className="game-data-table" style={styles.table}>
                        <thead>
                            <tr>
                                <th style={{...styles.cell, fontWeight: 'bold'}}>Game</th>
                                <th style={{...styles.cell, fontWeight: 'bold'}}>Avg Viewers</th>
                                <th style={{...styles.cell, fontWeight: 'bold'}}>Time Streamed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedGameData.map((game) => (
                                <tr key={game.gameId}>
                                    <td style={styles.cell}>{game.gameName}</td>
                                    <td style={{...styles.cell, color: game.avgViewers > totalAverage ? 'green' : 'red'}}>{game.avgViewers}</td>
                                    <td style={styles.cell}>{formatTime(game.timeStreamed)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>No game data available for this period.</p>
            )}
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    table: {
        borderCollapse: 'collapse',
        width: '100%',
    },
    cell: {
        padding: '6px 16px',
        borderBottom: '1px solid #bbb',
    },
};


