import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

export const TwitchLogin: React.FunctionComponent = () => {
    const location = useLocation();
    const [username, setUsername] = useState('friend');

    const loaded = useRef<boolean>(false);

    useEffect(() => {
        if (loaded.current) {
            return;
        }
        const uriParams = new URLSearchParams(location.search);
        const authCode = uriParams.get("code");

        // Hide URI parameters from the address bar without triggering a reload
        window.history.replaceState({}, document.title, window.location.pathname);

        loaded.current = true;

        if (authCode) {
            fetch('https://id.livedb.tv/v1/twitch/authorization', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'https://livedb.tv',
                    'X-Authorization-Code': authCode
                }
            })
            .then(response => {
                if (response.ok) {
                    response.text().then(data => {
                        localStorage.setItem('session', data);
                        const sessionData = JSON.parse(data);
                        setUsername(sessionData.username);
                    });
                } else {
                    console.error('Failed to fetch token:', response.statusText);
                }
            })
            .catch(error => {
                console.error('Error sending authorization code to backend:', error);
            });
        }
    }, [location.search]);

    return (
        <div style={{ maxWidth: '30em', margin: '50px auto' }}>
            <h2>
                Welcome to LiveDB, <span key={username} style={{ animation: 'fadeIn 0.5s' }}>
                    {username}
                </span>!
            </h2>
            <style>{`
                @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
            `}</style>
        </div>
    )
}
export default TwitchLogin;
