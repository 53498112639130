import React from "react";

export interface Stream {
  provider: string;
  stream_id: string;
  provider_id: string;
  broadcaster_name: string;
  started_at: string;
}

interface SearchBarProps {
  onSearch: (username: string) => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchBar: React.FC<SearchBarProps> = ({ onSearch, inputValue, setInputValue }) => {
    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            onSearch(inputValue.toLowerCase());
        }
    };

    return (
        <div>
            <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} onKeyDown={handleKeyPress} placeholder="forsen" />
        </div>
    );
};

interface StreamsListProps {
  streams: Stream[];
}

export const StreamsList: React.FC<StreamsListProps> = ({ streams }) => {
    return (
        <div>
            {streams.map((stream, index) => (
                <div key={index}>
                    <a href={`/stats/${stream.stream_id}`}>{new Date(stream.started_at).toLocaleString()}</a>
                </div>
            ))}
        </div>
    );
};