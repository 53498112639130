import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/style.css'

import WithTheme, { DefaultTheme } from './styles/theme';
import reportWebVitals from './reportWebVitals';
import Header from './components/header'
import PageRouter from './pages/routes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    {WithTheme(DefaultTheme,
      <>
        <Header />
        <PageRouter />
      </>
    )}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
