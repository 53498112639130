import React, { useEffect, useState } from 'react';

import { AppBar, Box, Toolbar, Typography, Button, Avatar } from '@mui/material';

const AppBarStyle = {
	position: 'relative',
	'@media(min-width: 992px)': {
		padding: '0 calc(1.5rem / 2)',
	}
};

const LogoStyle = {
	fontWeight: 600,
	userSelect: 'none',
	fontSize: '2.5rem',
    cursor: 'pointer', 
    flexGrow: 1, 
    marginBottom: { xs: '1rem', sm: '0' }
};

export const Header: React.FunctionComponent = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');

    useEffect(() => {
        const isDarkMode = localStorage.getItem('dark-mode') === 'true';
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
        }

        try {
            const sessionData = localStorage.getItem('session');
            if (sessionData) {
              const session = JSON.parse(sessionData);

              if (new Date(session.expiresAt) > new Date()) {
                setIsLoggedIn(true);
                setUsername(session.username);
                setAvatarUrl(session.avatarUrl);
              }
            }
        } catch (error) {
            console.error('Error parsing session data:', error);
        }
    }, []);

    const toggleDarkMode = () => {
        document.body.classList.toggle('dark-mode');
        localStorage.setItem('dark-mode', String(document.body.classList.contains('dark-mode')));
    };

    return (
        <AppBar sx={AppBarStyle}>
            <Toolbar sx={{ padding: '1rem 1rem', paddingBottom: '0.5rem', margin: '0 auto', width: '100%', display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between' }}>
                <Typography component="div" sx={ LogoStyle} onClick={() => { window.location.href = '/'; }}>
                    LiveDB
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: { sm: 'auto' } }}>
                    <Button variant="outlined" color="inherit" sx={{ marginRight: 2 }} onClick={toggleDarkMode}>
                        Toggle Dark Mode
                    </Button>
                    {isLoggedIn ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => { window.location.href = '/user'; }}>
                            <Avatar 
                                src={avatarUrl} 
                                alt={username} 
                                sx={{ 
                                    width: 32,
                                    height: 32,
                                    marginRight: 1,
                                    border: '2px solid #ffffff',
                                }} 
                            />
                            <Typography variant="body1">{username}</Typography>
                        </Box>
                    ) : (
                        <Button id="login-button" variant="contained" color="secondary" onClick={() => { window.location.href = '/login'; }}>
                            Login
                        </Button>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
