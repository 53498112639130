import { createBrowserRouter, RouterProvider, RouteObject } from 'react-router-dom';

import Home from '.';

import UserSearch from './stats/';
import Stream from './stats/stream';

import Login from './login/';
import TwitchLogin from './login/twitch/redirect';

import User from './user/user';

export interface Route {
	path: string;
	element: JSX.Element;
}

export const Routes: Route[] = [
	{ path: '/', element: <Home /> },
	{ path: '/stats', element: <UserSearch /> },
	{ path: '/stats/:stream_id', element: <Stream /> },
	{ path: '/login', element: <Login /> },
	{ path: '/login/twitch/redirect', element: <TwitchLogin /> },
	{ path: '/user', element: <User /> }
];

function getRoutes(routes: Route[]): RouteObject[] {
	let data: RouteObject[] = [];
	for(let route of routes) {
		data.push(route);
	}
	return data;
}

export const PageRouter: React.FunctionComponent = () => {
	const routes = getRoutes(Routes);
	return <RouterProvider router={createBrowserRouter(routes)} />
}

export default PageRouter;