import React from 'react';

import { Chart } from "react-google-charts";

import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

const baseURL: string = 'https://api.livedb.tv/v1/'
const apiToken: string = 'INCYtnv3hbRC0kMx17uYOYppRXPxupBL'

function generateHtmlTooltip(date: Date, viewcount: any, category: any, title: any) {
    return `<div style='font-size:1rem; white-space: nowrap; padding:5px'> <b>${date.toLocaleTimeString()}</b> </br >
            <b>Viewcount:</b> ${viewcount}</br >
            ${category}</br>
            ${title}</div >`

}

async function doRequest(endpoint: string) {
    const data = await fetch(`${baseURL}${endpoint}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-API-Token': apiToken,
        },
    });

    return data
}

export const Stream: React.FunctionComponent = () => {
    const { stream_id } = useParams();
    const [chartData, setChart] = useState<any[]>([]);

    const [loading, setLoading] = useState<boolean>(true);

    const [streamData, setStreamData] = useState<any>({});

    const peakViewcount = useRef(10);

    useEffect(() => {
        const fetchStreamData = async () => {
            const streamDataResult = await doRequest(`data/streams/twitch/${stream_id}`)

            const streamData = await streamDataResult.json();

            setStreamData(streamData);
        }

        const fetchChartData = async () => {
            const viewersResult = await doRequest(`data/streams/twitch/${stream_id}/viewers`);
            const titlesResult = await doRequest(`data/streams/twitch/${stream_id}/titles`);
            const categoriesResult = await doRequest(`data/streams/twitch/${stream_id}/games`);

            const viewersData = await viewersResult.json();
            const titlesData = await titlesResult.json();
            const categoriesData = await categoriesResult.json();

            let categories: string[] = [];

            categoriesData.data.forEach((item: any) => {
                for (let i = 0; i < categories.length; i++) {
                    if (item.n === categories[i]) {
                        return
                    }
                }

                categories.push(item.n);
            })

            let firstRow: any[] = [
                'Time',
                { 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } },
                'Viewcount',
                { type: 'string', role: 'annotation' }
            ];

            categories.forEach((item: string) => {
                firstRow.push(item);
            })

            viewersData.data.forEach((item: any) => {
                if (item.v > peakViewcount.current) {
                    peakViewcount.current = item.v;
                }
            })

            let lastCategory: string = '';
            let currentCategory: string = '';
            let currentTitle: string = '';

            let data: any[] = [firstRow];
            viewersData.data.forEach((item: any) => {

                let date = new Date(item.t * 1000);

                let annotation = null;

                // see if title got updates this time bucket
                for (let i = 0; i < titlesData.data.length; i++) {
                    let titleDate = new Date(titlesData.data[i].t * 1000);
                    if (date.getTime() !== titleDate.getTime()) {
                        continue
                    }

                    annotation = 'New title';
                    currentTitle = titlesData.data[i].n;

                    break
                }

                // update current category if updated
                for (let i = 0; i < categoriesData.data.length; i++) {
                    //TODO: implement this so it matches even if there is no viewercount data for the exact timestamp of the category change
                    let categoryDate = new Date(categoriesData.data[i].t * 1000)
                    if (date.getTime() !== categoryDate.getTime()) {
                        continue
                    }

                    currentCategory = categoriesData.data[i].n;
                    if (lastCategory === '') {
                        lastCategory = currentCategory;
                    }
                }

                let row: any[] = [
                    date,
                    generateHtmlTooltip(date, item.v, currentCategory, currentTitle),
                    item.v,
                    annotation
                ]

                for (let i = 0; i < categories.length; i++) {
                    if (categories[i] === lastCategory) {
                        // add 5% to peak viewers so the graph doesn't cut off at the top
                        row.push(peakViewcount.current * 105 / 100);
                        continue
                    }
                    row.push(0);
                }

                lastCategory = currentCategory;

                data.push(row);
            });

            setChart(data);
            setLoading(false);
        };

        fetchChartData();
        fetchStreamData();
    }, [stream_id]);

    return (
        <div style={{ padding: '0 15px' }}>
            <div id='streamInfo' style={{ display: 'flex', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '2rem' }}>
                <h4 style={{ margin: '0', marginRight: '5px' }}>Broadcaster:</h4>
                <a href={`https://twitch.tv/${streamData.broadcaster_name}`} style={{ margin: '0', paddingRight: '2rem' }} target="_blank" rel="noopener noreferrer">{streamData.broadcaster_name}</a>

                <h4 style={{ margin: '0', marginRight: '5px' }}>Stream date:</h4>
                <p style={{ margin: '0', paddingRight: '2rem' }}>{new Date(streamData.started_at).toDateString()}</p>

                <h4 style={{ margin: '0', marginRight: '5px' }}>Stream duration:</h4>
                <p style={{ margin: '0', paddingRight: '2rem' }}>{Math.round((new Date(streamData.ended_at).getTime() - new Date(streamData.started_at).getTime()) / 360000) / 10} hours</p>

                <h4 style={{ margin: '0', marginRight: '5px' }}>Peak viewcount:</h4>
                <p style={{ margin: '0', paddingRight: '2rem' }}>{peakViewcount.current}</p>
            </div>

            {!loading &&
                <Chart
                    chartType='ComboChart'
                    data={chartData}
                    width={'100%'}
                    height={'400px'}
                    options={{
                        title: 'Viewership over time',
                        hAxis: { title: 'Time' },
                        vAxis: {
                            title: 'Viewercount',
                            viewWindow: {
                                // add 5% to peak viewers so the graph doesn't cut off at the top
                                max: peakViewcount.current * 105 / 100
                            }
                        },
                        seriesType: 'steppedArea',
                        annotations: {
                            style: 'line',
                            textStyle: {
                                fontSize: 12
                            }
                        },
                        enableInteractivity: false,
                        focusTarget: 'category',
                        tooltip: { isHtml: true },
                        lineWidth: 0,
                        series: {
                            0: {
                                type: 'line',
                                lineWidth: 4,
                                enableInteractivity: true,
                            }
                        }
                    }}
                />
            }

        </div>
    );
}

export default Stream;

