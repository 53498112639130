import React from 'react';

// import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import TwitchLoginButton from '../../components/login/twitch';

export const Login: React.FunctionComponent = () => {
    return (
        <div style={{ maxWidth: '20em', margin: '50px auto' }}>
            <h3>Log in:</h3>
            {/* <GoogleOAuthProvider clientId="590220487805-s3e19b1hn4h60q8q957f6a2rjb9o2u2v.apps.googleusercontent.com">
                <GoogleLogin
                    text='continue_with'
                    size='medium'
                    theme='filled_blue'
                    useOneTap = {false}
                    onSuccess={credentialResponse => {
                        console.log(credentialResponse);
                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                />
            </GoogleOAuthProvider> */}

            <TwitchLoginButton />
        </div>
    )
}

export default Login;