import React from 'react';

import { useEffect, useState } from 'react';


import { SearchBar, StreamsList, Stream } from '../../components/search/user';
import UserCategoryPerformance from '../../components/stats/userCategories';


const baseURL: string = 'https://api.livedb.tv/v1/'

export const UserSearch: React.FunctionComponent = () => {
    const [username, setUsername] = useState<string>('forsen');

    const [streams, setStreams] = useState<Stream[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetch(`${baseURL}data/streams/twitch/username/${username}`, {
                    headers: {
                    'Content-Type': 'application/json',
                    'X-API-Token': 'INCYtnv3hbRC0kMx17uYOYppRXPxupBL',
                    },
                });
                if (!result.ok) {
                    throw new Error(`HTTP error! status: ${result.status}`);
                }
                const data = await result.json();
                setStreams(data.data);
                setError(null);
            } catch (error: any) {
                console.error('A problem occurred when fetching the data: ', error);
                setError(error.message);
            }
        };

      fetchData();
    }, [username]);

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'row', 
            flexWrap: 'wrap',
            justifyContent: 'space-between', 
            margin: '0 auto', 
            padding: '0 15px', 
            maxWidth: '80em' 
        }}>
            <div style={{ 
                flex: '1 1 300px', 
                marginRight: '20px', 
                marginBottom: '20px' 
            }}>
                <h2>User:</h2>
                <SearchBar onSearch={setUsername} inputValue={inputValue} setInputValue={setInputValue} />
                {error && <p style={{ color: 'red' }}>{error}</p>}
    
                <h2>Streams:</h2>
                <StreamsList streams={streams} />
            </div>
            <div style={{ 
                flex: '1 1 300px', 
                marginLeft: '20px' 
            }}>
                <UserCategoryPerformance username={username}/>
            </div>
        </div>
    );
};

export default UserSearch;
