import React from 'react';

export const Home: React.FunctionComponent = () => {
  return (
    <div style={{ maxWidth: '80em', margin: '40px auto', padding: '0 20px'}}>
      <h2 style={{ textAlign: 'center', fontSize: '2.5em', marginBottom: '20px', paddingTop: '20px' }}>Welcome to LiveDB</h2>

      <p style={{ marginBottom: '1.5em', textAlign: 'center', fontSize: '1.2em'}}>
        The ultimate analytics platform for Twitch streamers.
      </p>

      <h3 style={{ marginBottom: '1em', fontSize: '1.8em' }}>Features:</h3>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', gap: '1.5em', marginBottom: '2em' }}>
        {[
          { title: 'Viewer Analytics', description: 'Track your audience growth over time', icon: '📈' },
          { title: 'Content Performance Metrics', description: 'Discover which games your viewers like', icon: '🎮' },
          { title: 'Competitor Benchmarking', description: 'See how you stack up against similar streamers', icon: '📊' },
          { title: 'Personalized Growth Strategies', description: 'Get tailored recommendations to boost your channel', icon: '🚀' }
        ].map((feature, index) => (
          <div key={index} style={{
            border: '1px solid #ddd',
            borderRadius: '12px',
            padding: '1.5em',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            transition: 'transform 0.3s ease-in-out',
          }}
          onMouseEnter={(e) => e.currentTarget.style.transform = 'translateY(-5px)'}
          onMouseLeave={(e) => e.currentTarget.style.transform = 'translateY(0)'}
          >
            <h4 style={{ marginTop: '0', marginBottom: '0.8em', fontSize: '1.4em', display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '10px', fontSize: '1.5em' }}>{feature.icon}</span>
              {feature.title}
            </h4>
            <p style={{ margin: '0', fontSize: '1.1em' }}>{feature.description}</p>
          </div>
        ))}
      </div>

      <p style={{ marginBottom: '1.5em', textAlign: 'center', fontSize: '1.2em' }}>
        Level up using our data!
      </p>

      <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
        <a href='/stats' 
           style={{ 
             display: 'inline-block', 
             padding: '12px 24px', 
             backgroundColor: '#4a4a6e',
             textDecoration: 'none', 
             borderRadius: '30px',
             color: '#eee',
             fontSize: '1.2em',
             transition: 'background-color 0.3s ease-in-out',
           }}
           onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#3e3e4a'}
           onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#4a4a6e'}>
          User Search
        </a>
      </div>
    </div>
  );
};

export default Home;
