import React from 'react';
import { Button } from '@mui/material';

export default function User() {
    const sessionData = localStorage.getItem('session');
    const session = JSON.parse(sessionData || '{}');



    return (
        <div style={{ maxWidth: '20em', margin: '0 auto', padding: '0 15px' }}>
            <h2>Welcome {session.username}!</h2>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    localStorage.removeItem('session');
                    window.location.href = '/';
                }}
                style={{ marginTop: '20px' }}
            >
                Log Out
            </Button>
        </div>
    );
}